.container {
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
}
html {
  scroll-behavior: smooth;
}
body #app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
body #app .page-container {
  flex-grow: 1;
}
body .page-full-width > .row {
  margin-left: 0;
  margin-right: 0;
}
body .page-description {
  font-weight: bold;
  margin-top: 14px;
}

h1 {
  line-height: 2.8rem;
}

h1.page-title,
h2.page-title {
  text-align: center;
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

.page-title h1 {
  text-align: center;
  color: #0a1974;
  font-weight: bold;
  font-size: 57px;
  text-transform: uppercase;
}

h2.page-title {
  font-size: 30px;
}

h3.page-sub-title {
  font-size: 25px;
}

hr.separator {
  height: 5px;
  background: #b2e500;
  width: 200px;
}

.form-group.required > .form-label:after,
.form-group.required > .form-check-label:after {
  content: ' *';
  color: red;
  margin-left: 2px;
  background-size: 7px 7px;
  height: 7px;
  width: 7px;
  font-size: 12px;
  vertical-align: top;
}

.spinner-wrapper .spinner-image {
  opacity: 0.5;
  margin-left: 40%;
  width: 100px;
  height: 100px;
}

@media only screen and (max-width: 480px) {
  h1,
  h2 {
    font-size: 2.3em !important;
  }
}
