.navbar {
  background-color: rgba(255, 255, 255, 0.7);
  display: initial;
}

.navbar:not(.navbar-scrolled) {
  top: 37px;
}

.navbar a.nav-link {
  font-size: 17px;
  color: #1d1d1b;
  text-transform: uppercase;
}

.navbar a.nav-link.active {
  font-weight: bold;
}

.user-menu-wrapper {
  margin: auto;
  margin-bottom: 0;
}

.account-menu-wrapper {
  margin-bottom: auto;
}

.customer-area-button {
  font-weight: bold;
  padding: 8px 30px;
  text-transform: lowercase;
}

.navbar-toggler {
  margin-left: auto;
  margin-right: 5%;
}

.navbar-brand img {
  width: 144px;
}

.navbar-scrolled {
  max-height: 64px;
}

.navbar-scrolled .navbar-brand img {
  width: 75px;
}

@media only screen and (min-width: 990px) and (max-width: 1008px) {
  .navbar a.nav-link {
    font-size: 16px;
  }
}
