body {
  font-family: 'Poppins';
  font-size: 14px;
}

a:hover:not(.no-opacity) {
  color: inherit;
  opacity: 0.7;
}

.mt-header {
  margin-top: 130px;
}

#app {
  white-space: pre-line;
}
