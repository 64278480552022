footer {
  background-color: #173067;
  color: white;
  font-size: 17px;
}

footer .fb-page > span {
  height: 225px !important;
}

footer .fb-page iframe {
  height: 225px !important;
}

footer .position-wrapper {
  color: #c6e2cb;
}

footer .logo-wrapper img {
  width: 89px;
}

footer a {
  color: white;
}

footer .sub-footer-wrapper {
  background-color: #11183e;
  color: white;
  font-size: 13px;
}

footer .logo-phone-wrapper::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0px;
  height: 80%;
  width: 1px;
  border-right: 2px solid #d2c6f7;
  margin-top: 1.2em;
}

footer .phone-wrapper {
  font-size: 17px;
}

footer .section-header {
  text-transform: uppercase;
  color: #9ec8fc;
  font-size: 17px;
}

footer .footer-sections-wrapper {
  display: flex;
  flex-wrap: wrap;
}

footer .footer-section {
  max-width: 145px;
}

@media only screen and (min-width: 1600px) {
  footer .footer-section,
  footer .fb-frame-wrapper {
    margin-left: 5rem !important;
  }
}

@media only screen and (max-width: 1000px) {
  footer .fb-frame-wrapper {
    flex: 1 1 100%;
  }
}

@media only screen and (max-width: 768px) {
  footer .footer-sections-wrapper {
    display: block;
  }
}
