.language-bar-wrapper {
  background-color: #0a1974;
  height: 37px;
}

.language-bar-wrapper .language-switcher-wrapper {
  display: flex;
  margin-right: 8%;
}

.language-bar-wrapper .social-links-wrapper {
  display: flex;
  margin-right: 2%;
}

.social-links-wrapper .social-link {
  margin-right: 13px;
}

.social-links-wrapper .phone-wrapper {
  color: white;
}

.social-links-wrapper .phone-wrapper:hover {
  color: white;
  opacity: 0.5;
}
