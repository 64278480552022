.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 15px 30px;
  font-size: 14px;
  line-height: 1.42857;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

.btn.btn-default:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.btn.btn-primary {
  color: white;
  background-color: #4aaf65;
  border-color: #4aaf65;
}

.btn.btn-primary:hover {
  color: white;
  background-color: #398439;
  border-color: #4aaf65;
}

@media only screen and (min-width: 960px) {
  button.btn.big-button {
    background-color: #4aaf65;
    width: 360px;
    color: white;
    padding: 22px 0;
    text-transform: uppercase;
    font-size: 19px;
    font-weight: bold;
  }

  button.btn.big-button:focus,
  button.btn.big-button:hover {
    background-color: #20863b;
    border-color: #20863b;
    color: white;
    box-shadow: inherit;
  }
}

@media only screen and (max-width: 480px) {
  button.btn.big-button {
    width: 200px;
    height: 50px;
  }
}
