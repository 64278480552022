.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.form-group .form-label,
.form-group .form-check-label {
  color: #2f2f2f;
  margin-bottom: 5px;
}

.form-group p.description {
  margin-top: 5px;
  margin-bottom: 10px;
  color: #737373;
}

form img {
  width: 220px;
  height: 220px;
}

.form-check.form-check-inline {
  margin-right: 1rem;
  padding-left: 1.5rem;
}

form .custom-radio img,
form .form-check-inline img,
form .form-check img {
  width: 150px;
  height: 150px;
}

div:not('.measurements-form') .form-group input[type='checkbox'] {
  width: 30px;
  height: 30px;
  margin-top: 0;
}

.custom-control.custom-radio,
.custom-control.custom-radio label,
.form-check.form-check-inline,
.form-check.form-check-inline input,
.form-check.form-check-inline label,
.form-check input,
.form-check label {
  cursor: pointer;
}

.form-check.form-check-inline.option-disabled,
.form-check.form-check-inline.option-disabled input,
.form-check.form-check-inline.option-disabled label,
.form-check.option-disabled input,
.form-check.option-disabled label {
  cursor: not-allowed;
}

.text-field-suffix-wrapper {
  align-items: center;
}

.form-group .field-description {
  color: #8395a3;
  font-size: 13px;
}
